// Bootstrap color
$light: #ebebeb;
$warning: #ee801a;
$new-ticket-color: #97cc42;
$assigned-ticket-color: #2cc0a7;
$blue-neodt2: rgba(111,168,220,1);
$red-neodtx: rgba(224,102,102,1);
$all: rgba(142,124,195,1);
$pending-ticket-color: #df233c;
$cost-ticket-color: #faa35b;
$grey-color: #666666;
